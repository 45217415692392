import React,{useEffect, useState} from 'react';
import bgImg from './images/physiotherapy.jpg';
import bgImg1 from './images/bg_2.jpg';
import abtImg from './images/physiotherapy1.jpg';
import person1Img from './images/person_5.jpg';
import person2Img from './images/person_6.jpg';
import person3Img from './images/person_7.jpg';
import person4Img from './images/person_8.jpg';
import person5Img from './images/person_1.jpg';
import person6Img from './images/person_2.jpg';
import person7Img from './images/person_3.jpg';
import gallery1Img from './images/imagess11.jpg';
import gallery2Img from './images/physio1.jpg';
import gallery3Img from './images/Physiotherapy-2.jpg';
import gallery4Img from './images/physio2.jpg';
import image1Img from './images/image_1.jpg';
import image2Img from './images/image_2.jpg';
import image3Img from './images/image_3.jpg';
import bgwhi from './images/bgwhite.jpg';
import bgwhite from './images/bgwhite2.jpg';
import icon1 from './images/backpain.svg';
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import 'jquery-timepicker/jquery.timepicker.css';
import 'scrollax';
import './flaticon/font/flaticon.css';
import 'magnific-popup';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './flaticon/font/flaticon.css';
import './icomoon/icomoon.svg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { IoMdFlower } from 'react-icons/io';
import { IoMdHappy } from 'react-icons/io';
import { IoMdStar } from 'react-icons/io';
import { IoMdMedkit } from 'react-icons/io';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Slide } from 'react-slideshow-image';
import BackPain from './svgicons/backpain';
import PhysioIcon1 from './svgicons/physio1';
import { format } from 'date-fns';
import 'owl.carousel';
import anime from 'animejs/lib/anime.es.js';
import FloatingIcons from './FloatingIcons';
import emailjs from 'emailjs-com';
import axios from 'axios';
import { IoLocationSharp, IoCallSharp, IoMailSharp, IoMapSharp, IoMedicalSharp } from 'react-icons/io5';
import { FaBars } from 'react-icons/fa';
import webicon from './images/websiteicon.png';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faAmbulance } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import Loader from './Loader';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import {faShare} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKuOwNC3-wBj6Gmsx_YGVWV-a_qlX9aXw",
  authDomain: "activecare-8b7d9.firebaseapp.com",
  databaseURL: "https://activecare-8b7d9-default-rtdb.firebaseio.com",
  projectId: "activecare-8b7d9",
  storageBucket: "activecare-8b7d9.appspot.com",
  messagingSenderId: "1028559468034",
  appId: "1:1028559468034:web:e87174fa0ed2459839991b",
  measurementId: "G-ZJETJE99MW"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();


const SERVICE_ID = 'service_v0lr0ed';
const TEMPLATE_ID = 'template_md2w22m';
const USER_ID = '4TwBIrI6jL3UErtjS';



const Homepage=()=>{

  const [isAdmin, setIsAdmin] = useState(false);

  
  useEffect(()=>{
    const checkAdminStatus=()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const userEmail=user.email;
        firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
          .then((snapshot)=>{
            if (snapshot.exists()) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          }).catch((error)=>{
            console.error("Error checking admin status:",error);
          })
      }else{
        setIsAdmin(false);
      }
  
    })
    }
    checkAdminStatus();
  },[]);

  
  //title name change
  const [brandName, setBrandName] = useState('');

 
  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const snapshot = await firebase.database().ref('Title/Clinic').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setBrandName(data);
        } else {
          console.error('Brand name not found in database');
        }
      } catch (error) {
        console.error('Error fetching brand name:', error);
      }
    };

    fetchBrandName();
  }, []);


  //SEO
  
  const [metaTitle, setMetaTitle] = useState('');
  const [keywords, setKeywords] = useState('');
  const [primaryKeywords, setPrimaryKeywords] = useState('');
  const [secondaryKeywords, setSecondaryKeywords] = useState('');
  const [message3, setMessage3] = useState(''); 


useEffect(() => {
    const metaDataRef = firebase.database().ref('metadata');
  
    // Fetch metadata from the database
    metaDataRef.once('value').then((snapshot) => {
        const metaData = snapshot.val();
        if (metaData) {
            setMetaTitle(metaData.metaTitle || '');
            setKeywords(metaData.keywords || '');
            setPrimaryKeywords(metaData.primaryKeywords || '');
            setSecondaryKeywords(metaData.secondaryKeywords || '');
  
        }
    }).catch((error) => {
        console.error('Error fetching metadata:', error);
    });
  }, []);
  useEffect(() => {

    

    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);



   //appointment form
   const [doctorName, setDoctorName] = useState('');
   const [doctorOptions, setDoctorOptions] = useState([]);
 
   
   useEffect(() => {
    const fetchDoctorOptions = () => {
      const doctorRef = db.ref('doctors_name');
      doctorRef.once('value', (snapshot) => {
        const doctors = snapshot.val();
        if (doctors) {
          const doctorNames = Object.values(doctors);
          setDoctorOptions(doctorNames);
        }
      });
    };
    fetchDoctorOptions();
  }, [db]);

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: ''
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [timeOptions1, setTimeOptions1] = useState([]);

  useEffect(() => {
    // Fetch the latest appointment count from the database
    const appointmentsRef = db.ref('Appointment_tab');
    appointmentsRef.once('value', (snapshot) => {
      const appointments = snapshot.val();
      // Get the count of existing appointments
      const count = appointments ? Object.keys(appointments).length : 0;
      setAppointmentCount(count);
    });
  }, []);

  useEffect(() => {
    const fetchAvailableTimes = async () => {
      if (formData.Appointmenttab_date) {
        const formattedDate = format(formData.Appointmenttab_date, 'yyyy-MM-dd'); // Change date format to match database

        const appointmentRef = firebase.database().ref('Appointment_tab');
        const snapshot = await appointmentRef.once('value');
        const appointments = snapshot.val() || {};
        const bookedTimes = [];

        // Collect booked times for the selected date
        Object.values(appointments).forEach((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date).toLocaleDateString('en-GB');
          const selectedDate = formData.Appointmenttab_date.toLocaleDateString('en-GB');

          if (appointmentDate === selectedDate) {
            bookedTimes.push(appointment.Appointmenttab_time);
          }
        });

        // Filter out booked times from defaultTimeOptions
        const availableTimes = defaultTimeOptions.filter(time => !bookedTimes.includes(time));
        setTimeOptions1(availableTimes);
      }
    };

    fetchAvailableTimes();
  }, [formData.Appointmenttab_date]); // Run effect when the date changes


  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, Appointmenttab_time: value });
  };

 
  const handleDoctorNameChange = (e) => {
    const { value } = e.target;
    setDoctorName(value); // Update doctorName state
    setFormData({ ...formData, Appointmenttab_doctorname: value }); // Update formData state
  };
  
  useEffect(() => {
    console.log(doctorData.map(doctor => doctor.doctor_id));
    // Other useEffect code...
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Store form data in Realtime Database

    try {
      const snapshot = await db.ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value');
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });

      console.log("Latest appointment:", latestAppointment);

      // Calculate the new ID
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;

      console.log("New appointment ID:", newAppointmentId);
      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname: 'Dr. Senthil Nathan',
      };

      updatedFormData.Appointmenttab_date = new Date(updatedFormData.Appointmenttab_date).toISOString();

      await db.ref('Appointment_tab').push(updatedFormData);

      console.log('Sending email with data:', {
        name: formData.Appointmenttab_name,
        date: new Date(formData.Appointmenttab_date).toLocaleString(), 
        time: formData.Appointmenttab_time,
        doctorName: formData.Appointmenttab_doctorname,
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
      });

      const emailData = {
        name: formData.Appointmenttab_name,
        date: formData.Appointmenttab_date,
        time: formData.Appointmenttab_time,
        doctorName: "Dr.Senthil Nathan",
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
        defaultemail: "ActiveCarePhysio22@gmail.com"
      };

      console.log('Sending email with data:', emailData); 

      const response = await axios.post('https://us-central1-activecare-8b7d9.cloudfunctions.net/api/send-email', emailData);

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }

      setFormData({
        Appointmenttab_id: '',
        Appointmenttab_name: '',
        Appointmenttab_email: '',
        Appointmenttab_date: new Date(),
        Appointmenttab_time: '',
        Appointmenttab_doctorname: '',
        Appointmenttab_phone: ''
      });
      setSuccessMessage('Appointment successfully scheduled!');
      setTimeout(() => {
        setSuccessMessage('');
        setModalVisible(false);
      }, 2000);
    } catch (error) {
      console.error('Error storing form data:', error);
    }
  };
   


  //form appointment
  const [timeOptions2, setTimeOptions2] = useState([]);
  const [successMessage3,setSuccessMessage3]=useState('');

  const [formData2, setFormData2] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: ''
  });

  const handleDoctorNameChange1 = (e) => {
    const { value } = e.target;
    setFormData2({ ...formData2, Appointmenttab_doctorname: value,doctorName: value }); // Update formData state
  };

  
  const handleTimeChange1 = (e) => {
    const { value } = e.target;
    setFormData2({ ...formData2, Appointmenttab_time: value });
  };

  
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData2({ ...formData2, [name]: value });
  };

  const defaultTimeOptions = [
    "10:00 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 AM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 01:00 PM",
    "04:00 PM - 04:30 PM",
    "04:30 PM - 05:00 PM",
    "05:00 PM - 05:30 PM",
    "05:30 PM - 06:00 PM",
    "06:00 PM - 06:30 PM",
    "06:30 PM - 07:00 PM",
    "07:00 PM - 07:30 PM",
    "07:30 PM - 08:00 PM"
  ];
  
  useEffect(() => {
    const fetchAvailableTimes = async () => {
      if (formData2.Appointmenttab_date) {
        const formattedDate = format(formData2.Appointmenttab_date, 'yyyy-MM-dd'); // Change date format to match database

        const appointmentRef = firebase.database().ref('Appointment_tab');
        const snapshot = await appointmentRef.once('value');
        const appointments = snapshot.val() || {};
        const bookedTimes = [];

        // Collect booked times for the selected date
        Object.values(appointments).forEach((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date).toLocaleDateString('en-GB');
          const selectedDate = formData2.Appointmenttab_date.toLocaleDateString('en-GB');

          if (appointmentDate === selectedDate) {
            bookedTimes.push(appointment.Appointmenttab_time);
          }
        });

        // Filter out booked times from defaultTimeOptions
        const availableTimes = defaultTimeOptions.filter(time => !bookedTimes.includes(time));
        setTimeOptions2(availableTimes);
      }
    };

    fetchAvailableTimes();
  }, [formData2.Appointmenttab_date]); // Run effect when the date changes


  
  const handleSubmit3 = async (e) => {
    e.preventDefault();
    
    try {
      const snapshot = await db.ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value');
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });
  
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;
      const updatedFormData = {
        ...formData2,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname:'Dr. Senthil Nathan',
      };
  
      updatedFormData.Appointmenttab_date = new Date(updatedFormData.Appointmenttab_date).toISOString();
  
      await db.ref('Appointment_tab').push(updatedFormData);

      console.log('Sending email with data:', {
        name: formData2.Appointmenttab_name,
        date: new Date(formData2.Appointmenttab_date).toLocaleString(), 
        time: formData2.Appointmenttab_time,
        doctorName: formData2.Appointmenttab_doctorname,
        phone: formData2.Appointmenttab_phone,
        email: formData2.Appointmenttab_email,
      });


      const emailData = {
        name: formData2.Appointmenttab_name,
        date: formData2.Appointmenttab_date,
        time: formData2.Appointmenttab_time,
        doctorName: "Dr.Senthil Nathan",
        phone: formData2.Appointmenttab_phone,
        email: formData2.Appointmenttab_email,
        defaultemail: "ActiveCarePhysio22@gmail.com"
      };
  
      console.log('Sending email with data:', emailData); 

      const response = await axios.post('https://us-central1-activecare-8b7d9.cloudfunctions.net/api/send-email', emailData);

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }
  
      setFormData2({
        Appointmenttab_id: '',
        Appointmenttab_name: '',
        Appointmenttab_email: '',
        Appointmenttab_date: new Date(),
        Appointmenttab_time: '',
        Appointmenttab_doctorname: '',
        Appointmenttab_phone: ''
      });
      setSuccessMessage3('Appointment successfully scheduled!');
      setTimeout(() => {
        setSuccessMessage3('');
        setModalVisible(false);
      }, 2000);
    } catch (error) {
      console.error('Error storing form data:', error);
    }
  };
  

  //Quote form database
  const [formData1, setFormData1] = useState({
    Quote_id: 0,
    Quote_name: '',
    Quote_email: '',
    Quote_phone: '',
    Quote_website: '',
    Quote_message: ''
  });
  const [successMessage1, setSuccessMessage1] = useState('');

  const handleSubmit1 = (event) => {
    event.preventDefault();

    const db = firebase.database();

    db.ref('Quote Form').orderByChild('Quote_id').limitToLast(1).once('value')
    .then((snapshot) => {
      let latestQuote = null;
      snapshot.forEach((childSnapshot) => {
        latestQuote = childSnapshot.val();
      });

      console.log("Latest appointment:", latestQuote);

      // Calculate the new ID
      const newQuoteId = latestQuote ? latestQuote.Quote_id + 1 : 1;

      console.log("New appointment ID:", newQuoteId);

      formData1.Quote_id = newQuoteId;

      return db.ref('Quote Form').push(formData1);
      })

    // Push form data to the database
      .then(() => {
        console.log('Quote Form data stored in Realtime Database');
        setFormData1({
          Quote_id:'',
          Quote_name: '',
          Quote_email: '',
          Quote_phone: '',
          Quote_website: '',
          Quote_message: ''
        });
        setSuccessMessage1('Quote submitted successfully!');
        setTimeout(() => {
          setSuccessMessage1('');
        }, 2000); // Clear the success message after 5 seconds
      })
      .catch((error) => {
        console.error('Error storing form data:', error);
        setSuccessMessage('Error submitting quote. Please try again.');
      });
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    setFormData1({
      ...formData1,
      [name]: value
    });
  };

  //subscribe our news letter
  const [email2, setEmail2] = useState('');
  const [successMessage2, setSuccessMessage2] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');
  const [latestId1, setLatestId1]=useState(0);

  
  useEffect(() => {
    const db = firebase.database();
    const fetchLatestId = async () => {
      try {
        const snapshot = await db.ref('Subscribe Form').orderByChild('subscribe_id').limitToLast(1).once('value');
        if (snapshot.exists()) {
          const latestEntry = snapshot.val();
          const latestIdValue = Object.values(latestEntry)[0].subscribe_id;
          setLatestId1(latestIdValue);
        } else {
          setLatestId1(0);
        }
      } catch (error) {
        console.error('Error fetching latest ID:', error);
      }
    };
    fetchLatestId();
  }, []);

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    const db = firebase.database();

    try {
      const newId = latestId1 + 1;
      // Push email to the database with field name 'subscribe_email'
      await db.ref('Subscribe Form').push({
        subscribe_id: newId,
        subscribe_email: email2
      });

      // Clear email field and set success message
      setEmail2('');
      setSuccessMessage2('Subscription successful!');
      setErrorMessage2('');
    } catch (error) {
      console.error('Error storing email:', error);
      setSuccessMessage2('');
      setErrorMessage2('Subscription failed. Please try again.');
    }
  };

  const handleChange2 = (event) => {
    setEmail2(event.target.value);
  };


  //footer address change
  const [officeInfo, setOfficeInfo] = useState({
    email: '',
    phoneNumber: '',
    address: ''
  });

  useEffect(() => {
    const fetchOfficeInfo = async () => {
      try {
        const snapshot = await firebase.database().ref('Address').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOfficeInfo({
            email: data.Address_email || '',
            phoneNumber: data.Address_number || '',
            phoneNumber1: data.Address_number1 || '',
            address: data.Address_place || ''
          });
        } else {
          console.error('Office information not found in database');
        }
      } catch (error) {
        console.error('Error fetching office information:', error);
      }
    };

    fetchOfficeInfo();
  }, []);

  //subscribe content taken in database
  const [newsletterContent, setNewsletterContent] = useState({
    title: '',
    description: ''
  });

  
  useEffect(() => {
    const fetchNewsletterContent = async () => {
      try {
        const snapshot = await firebase.database().ref('Subscribe').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setNewsletterContent({
            title: data.Subscribe_title || '',
            description: data.Subscribe_description || ''
          });
        } else {
          console.error('Newsletter content not found in database');
        }
      } catch (error) {
        console.error('Error fetching newsletter content:', error);
      }
    };

    fetchNewsletterContent();
  }, []);

  //about content taken in database
  const [aboutContent, setAboutContent] = useState({
    title: '',
    description: '',
    services: []
  });

  useEffect(() => {
    const fetchAboutContent = async () => {
      try {
        const snapshot = await firebase.database().ref('About').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          const services = Object.values(data).filter(item => typeof item === 'object');
          const { About_title, About_description } = data;

          setAboutContent({
            title: About_title || '',
            description: About_description || '',
            services: services.map(service => ({
              title: service.About_subtitle || '',
              description: service.About_subdescription || ''
            }))
          });
        } else {
          console.error('About section content not found in database');
        }
      } catch (error) {
        console.error('Error fetching about section content:', error);
      }
    };

    fetchAboutContent();
  }, []);



//doctor service content given

const [aboutContent1, setAboutContent1] = useState({
  title: '',
  description: '',
  services: []
});

useEffect(() => {
  const fetchAboutContent1 = async () => {
    try {
      const snapshot = await firebase.database().ref('Services1').once('value');
      if (snapshot.exists()) {
        const data = snapshot.val();
        const services = Object.values(data).filter(item => typeof item === 'object');
        const { Service_title, Service_description } = data;

        setAboutContent1({
          title: Service_title || '',
          description: Service_description || '',
          services: services.map(service => ({
            title: service.Service_subtitle || '',
            description: service.Service_subdescription || ''
          }))
        });
      } else {
        console.error('About section content not found in database');
      }
    } catch (error) {
      console.error('Error fetching about section content:', error);
    }
  };

  fetchAboutContent1();
}, []);


  

  const [serviceContent, setServiceContent] = useState({
    title: '',
    description: ''
  });

  useEffect(() => {
    const fetchServiceContent = async () => {
      try {
        const snapshot = await firebase.database().ref('Services').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          const { Service_title, Service_description } = data;

          setServiceContent({
            title: Service_title || '',
            description: Service_description || ''
          });
        } else {
          console.error('Service section content not found in database');
        }
      } catch (error) {
        console.error('Error fetching service section content:', error);
      }
    };

    fetchServiceContent();
  }, []);

  const [services1, setServices1] = useState([]);

  
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const snapshot = await firebase.database().ref('Services').once('value');
        const servicesData = snapshot.val();
        setServices1(Object.values(servicesData));
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);


  //isloggedin
  const [isLoggedIn, setIsLoggedIn] = useState(false);
useEffect(() => {
  // Check if user is already logged in
  const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  });
  return unsubscribe
}, []);

//logout
const handleLogout = () => {
  firebase.auth().signOut()
    .then(() => {
      setIsLoggedIn(false);
      // Redirect to home page or do any necessary clean up
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Sign out error:', error);
    });
};

const [dropdownOpen, setDropdownOpen] = useState(false);

const toggleDropdown = () => {
  if (window.innerWidth <= 768) { 
    setDropdownOpen(prevState => !prevState);
  }
};

const handleMouseEnter = () => {
  setDropdownOpen(true);
};

// Function to handle mouse leave event
const handleMouseLeave = () => {
  setDropdownOpen(false);
};




//achievement value
const [achievements, setAchievements] = useState([]);
const [counts, setCounts] = useState({
  experience: 0,
  starRating: 0,
  happyPatients: 0,
  newVisits: 0,
});

useEffect(() => {
  const interval = setInterval(() => {
    setCounts(prevCounts => ({
      experience: Math.min(prevCounts.experience + 1, 15),
      starRating: Math.min(prevCounts.starRating + 1, 5),
      happyPatients: Math.min(prevCounts.happyPatients + 50, 5000),
      newVisits: Math.min(prevCounts.newVisits + 8, 800),
    }));
  }, 50); // Adjust the interval as needed

  return () => clearInterval(interval);
}, []);

  useEffect(() => {
    const fetchAchievements = async () => {
      const database = firebase.database();
      const achievementsRef = database.ref('Achievement');

      try {
        const snapshot = await achievementsRef.once('value');
        const data = snapshot.val();
        const achievementsArray = Object.values(data).filter(item => typeof item === 'object');
        setAchievements(achievementsArray);

        const initialCounts = {};
        achievementsArray.forEach(achievement => {
          initialCounts[achievement.Achievement_id] = 0;
        });
        setCounts(initialCounts);

        
        
      } catch (error) {
        console.error('Error fetching achievements:', error);
      }
    };

    fetchAchievements();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCounts(prevCounts => {
  //       const updatedCounts = {};
  //       achievements.forEach(achievement => {
  //         const { Achievement_id, Achievement_value } = achievement;
  //         const increment = 500;
  //         updatedCounts[Achievement_id] = Math.min(prevCounts[Achievement_id]  + increment, Achievement_value);
  //       });
  //       return updatedCounts;
  //     });
  //   }, 100); // Adjust the interval as needed

  //   return () => clearInterval(interval);
  // }, [achievements]);

  // useEffect(() => {
  //   console.log('Achievements:', achievements);
  //   console.log('Counts:', counts);
  // }, [achievements, counts]);

  const [achievementData1, setAchievementData1] = useState(null);

  useEffect(() => {
    const fetchAchievementData = async () => {
      const database = firebase.database();
      const achievementRef = database.ref('Achievement');

      try {
        const snapshot = await achievementRef.once('value');
        const data = snapshot.val();
        setAchievementData1(data);
      } catch (error) {
        console.error('Error fetching achievement data:', error);
      }
    };

    fetchAchievementData();
  }, []);

  //doctor content connect database
  const [dentistData, setDentistData] = useState(null);

  useEffect(() => {
    const fetchDentistData = async () => {
      const database = firebase.database();
      const dentistRef = database.ref('doctors');

      try {
        const snapshot = await dentistRef.once('value');
        const data = snapshot.val();
        setDentistData(data);
      } catch (error) {
        console.error('Error fetching dentist data:', error);
      }
    };

    fetchDentistData();
  }, []);


  const [doctorData, setDoctorData] = useState([]);

  
  useEffect(() => {
    const fetchDoctorData = async () => {
      const database = firebase.database();
      const doctorRef = database.ref('doctors');

      try {
        const snapshot = await doctorRef.once('value');
        const data = snapshot.val();
        const doctors = Object.values(data).filter(item => typeof item === 'object');

        // Fetch image URLs from Firebase Storage
        const promises = doctors.map(async doctor => {
          const imageUrl = await firebase.storage().refFromURL(doctor.doctor_image_url).getDownloadURL();
          return { ...doctor, imageUrl };
        });

        const doctorsWithImages = await Promise.all(promises);

        setDoctorData(doctorsWithImages);
      } catch (error) {
        console.error('Error fetching doctor data:', error);
      }
    };

    fetchDoctorData();
  }, []);


  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    animateOut: 'fadeOut', // Add animation effect for slide out
    animateIn: 'fadeIn', // Add animation effect for slide in
    responsive: {
      0: {
        items: 1
      },
      768:{
        items: 2
      }
    }
  };

//testimony
  const [testimonialData, setTestimonialData] = useState([]);

  useEffect(() => {
    const fetchTestimonialData = async () => {
      const database = firebase.database();
      const testimonialRef = database.ref('reviews'); // Update this to the correct path

      try {
        const snapshot = await testimonialRef.once('value');
        const data = snapshot.val();
        console.log("data:",data);
        const testimonials = Object.values(data).filter(item => item !== null);
        console.log("testimonials:",testimonials);
        setTestimonialData(testimonials);
      } catch (error) {
        console.error('Error fetching testimonial data:', error);
      }
    };

    fetchTestimonialData();
  }, []);
 

  useEffect(() => {
    // AOS initialization
    AOS.init({
      duration: 800,
      easing: 'slide'
    });
  },[]);

  /* useEffect(() => {
    // Stellar initialization
    $(window).stellar({
      responsive: true,
      parallaxBackgrounds: true,
      parallaxElements: true,
      horizontalScrolling: false,
      hideDistantElements: false,
      scrollProperty: 'scroll',
      horizontalOffset: 0,
      verticalOffset: 0
    });
  },[]);*/

    useEffect(() => {
    // Full height function
    const fullHeight = () => {
      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(function() {
        $('.js-fullheight').css('height', $(window).height());
      });
    };
    fullHeight();

  
  },[]);
  
  
  $.Scrollax();
   
  /*
   useEffect(() => {
    var carousel = function() {
      $('.home-slider').owlCarousel({
        loop:true,
        autoplay: true,
        margin:0,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav:false,
        autoplayHoverPause: false,
        items: 1,
        navText : ["<span class='ion-md-arrow-back'></span>","<span class='ion-chevron-right'></span>"],
        responsive:{
          0:{
            items:1,
            nav:false
          },
          600:{
            items:1,
            nav:false
          },
          1000:{
            items:1,
            nav:false
          }
        }
      });
      $('.carousel-testimony').owlCarousel({
        center: true,
        loop: true,
        items:1,
        margin: 30,
        stagePadding: 0,
        nav: true,
        navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
        responsive:{
          0:{
            items: 1
          },
          600:{
            items: 1
          },
          1000:{
            items: 1
          }
        }
      });
  
    };
    carousel();
  },[]);
  */
  
  

  useEffect(() => {
  
    $('nav .dropdown').hover(function(){
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function(){
      var $this = $(this);
        // timer;
      // timer = setTimeout(function(){
        $this.removeClass('show');
        $this.find('> a').attr('aria-expanded', false);
        // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
        $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });
  
  
    $('#dropdown04').on('show.bs.dropdown', function () {
      console.log('show');
    });
  
    // scroll
    var scrollWindow = function() {
      $(window).scroll(function(){
        var $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');
  
        if (st > 50) {
          if ( !navbar.hasClass('scrolled') ) {
            navbar.addClass('scrolled');	
          }
        } 
        if (st < 150) {
          if ( navbar.hasClass('scrolled') ) {
            navbar.removeClass('scrolled sleep');
          }
        } 
        if ( st > 350  ) {
          if ( !navbar.hasClass('awake') ) {
            navbar.addClass('awake');	
          }
          
          if(sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if ( st < 350 ) {
          if ( navbar.hasClass('awake') ) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if(sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();
  
    /*
    var counter = function() {
      
      $('#section-counter').waypoint( function( direction ) {
  
        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
  
          var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
          $('.number').each(function(){
            var $this = $(this),
              num = $this.data('number');
              console.log(num);
            $this.animateNumber(
              {
                number: num,
                numberStep: comma_separator_number_step
              }, 7000
            );
          });
          
        }
  
      } , { offset: '95%' } );
  
    }
    counter();
    
    var contentWayPoint = function() {
      var i = 0;
      $('.ftco-animate').waypoint( function( direction ) {
  
        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
          
          i++;
  
          $(this.element).addClass('item-animate');
          setTimeout(function(){
  
            $('body .ftco-animate.item-animate').each(function(k){
              var el = $(this);
              setTimeout( function () {
                var effect = el.data('animate-effect');
                if ( effect === 'fadeIn') {
                  el.addClass('fadeIn ftco-animated');
                } else if ( effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft ftco-animated');
                } else if ( effect === 'fadeInRight') {
                  el.addClass('fadeInRight ftco-animated');
                } else {
                  el.addClass('fadeInUp ftco-animated');
                }
                el.removeClass('item-animate');
              },  k * 50, 'easeInOutExpo' );
            });
            
          }, 100);
          
        }
  
      } , { offset: '95%' } );
    };
    contentWayPoint();
    */
  
    // navigation
    var OnePageNav = function() {
      $(".smoothscroll[href^='#'], #ftco-nav ul li a[href^='#']").on('click', function(e) {
         e.preventDefault();
  
         var hash = this.hash,
             navToggler = $('.navbar-toggler');
         $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 700, 'easeInOutExpo', function(){
          window.location.hash = hash;
        });
  
  
        if ( navToggler.is(':visible') ) {
          navToggler.click();
        }
      });
      $('body').on('activate.bs.scrollspy', function () {
        console.log('nice');
      })
    };
    OnePageNav();
  
    
    // magnific popup
    $('.image-popup').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      fixedContentPos: true,
      mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
       gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        verticalFit: true
      },
      zoom: {
        enabled: true,
        duration: 300 // don't foget to change the duration also in CSS
      }
    });
  
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
  
      fixedContentPos: false
    });
    /*
    $('.appointment_date').datepicker({
      'format': 'm/d/yyyy',
      'autoclose': true
    });
    $('.appointment_time').timepicker();
  
    */
  }, []);

  const handleMakeAppointmentClick = () => {
    // Open the modal when "Make an Appointment" link is clicked
    document.getElementById('modalRequest').classList.add('show');
    document.body.classList.add('modal-open');
  };



  const owlOptions = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  // const slickOptions = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3500,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  //payment gateway
  const [successMessage6,setSuccessMessage6]= useState('');
  const [failureMessage6,setFailureMessage6]= useState('');
  
  const handleSubmit6=()=>{
    
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString('en-IN');
  
    
    firebase.database().ref('bookingId').once('value', (snapshot) => {
      const currentBookingId = snapshot.val() || 0; // Get current booking ID or default to 0
      const newBookingId = currentBookingId + 1; // Increment the booking ID
  
      const currentUser = firebase.auth().currentUser;
  
      if (!currentUser) {
        alert('Please log in to make a booking.');
        return; // Exit the function if user is not logged in
      }
  
      const user_id = currentUser ? currentUser.uid : '';
      const user_email = currentUser ? currentUser.email : '';
      const user_mobile = currentUser ? currentUser.phoneNumber :''; // You need to implement fetching user mobile number
      const user_name = currentUser ? currentUser.displayName :''; // You need to implement fetching user name
  
      firebase.database().ref('bookingId').set(newBookingId).then(() => {
  
        firebase.database().ref('bookingcards').push({
          booking_Id: newBookingId,
          booking_DateTime : formattedDateTime,
          Booking_Status: 'Pending',
          Booking_Price: 'Rs.2000',
          Booking_Category: 'Basic',
          user_id,
          user_email,
          user_mobile,
          user_name
  }) 
  .then(() => {
    const queryParams=`?categoryName=${encodeURIComponent('Basic')}`;
    window.location.href = `/confirmation${queryParams}`;
  })
  .catch((error) => {
    setFailureMessage6('Failed to submit booking. Please try again.');
  });
    }).catch((error) => {
      setFailureMessage6('Failed to submit booking. Please try again.');
    });
  }).catch((error) => {
    setFailureMessage6('Failed to submit booking. Please try again.');
  });
  };
  


  //payment gateway
  const [successMessage7,setSuccessMessage7]= useState('');
  const [failureMessage7,setFailureMessage7]= useState('');
  
  const handleSubmit7=()=>{
    
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString('en-IN');
  
    
    firebase.database().ref('bookingId').once('value', (snapshot) => {
      const currentBookingId = snapshot.val() || 0; // Get current booking ID or default to 0
      const newBookingId = currentBookingId + 1; // Increment the booking ID
  
      const currentUser = firebase.auth().currentUser;
  
      if (!currentUser) {
        alert('Please log in to make a booking.');
        return; // Exit the function if user is not logged in
      }
  
      const user_id = currentUser ? currentUser.uid : '';
      const user_email = currentUser ? currentUser.email : '';
      const user_mobile = currentUser ? currentUser.phoneNumber :''; // You need to implement fetching user mobile number
      const user_name = currentUser ? currentUser.displayName :''; // You need to implement fetching user name
  
      firebase.database().ref('bookingId').set(newBookingId).then(() => {
  
        firebase.database().ref('bookingcards').push({
          booking_Id: newBookingId,
          booking_DateTime : formattedDateTime,
          Booking_Status: 'Pending',
          Booking_Price: 'Rs.4000',
          Booking_Category: 'Standard',
          user_id,
          user_email,
          user_mobile,
          user_name
  }) 
  .then(() => {
    const queryParams=`?categoryName=${encodeURIComponent('Standard')}`;
    window.location.href = `/confirmation${queryParams}`;
  })
  .catch((error) => {
    setFailureMessage7('Failed to submit booking. Please try again.');
  });
    }).catch((error) => {
      setFailureMessage7('Failed to submit booking. Please try again.');
    });
  }).catch((error) => {
    setFailureMessage7('Failed to submit booking. Please try again.');
  });
  };
  

  //payment gateway
  const [successMessage8,setSuccessMessage8]= useState('');
  const [failureMessage8,setFailureMessage8]= useState('');
  
  const handleSubmit8=()=>{
    
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString('en-IN');
  
    
    firebase.database().ref('bookingId').once('value', (snapshot) => {
      const currentBookingId = snapshot.val() || 0; // Get current booking ID or default to 0
      const newBookingId = currentBookingId + 1; // Increment the booking ID
  
      const currentUser = firebase.auth().currentUser;
  
      if (!currentUser) {
        alert('Please log in to make a booking.');
        return; // Exit the function if user is not logged in
      }
  
      const user_id = currentUser ? currentUser.uid : '';
      const user_email = currentUser ? currentUser.email : '';
      const user_mobile = currentUser ? currentUser.phoneNumber :''; // You need to implement fetching user mobile number
      const user_name = currentUser ? currentUser.displayName :''; // You need to implement fetching user name
  
      firebase.database().ref('bookingId').set(newBookingId).then(() => {
  
        firebase.database().ref('bookingcards').push({
          booking_Id: newBookingId,
          booking_DateTime : formattedDateTime,
          Booking_Status: 'Pending',
          Booking_Price: 'Rs.6000',
          Booking_Category: 'Premium',
          user_id,
          user_email,
          user_mobile,
          user_name
  }) 
  .then(() => {
    const queryParams=`?categoryName=${encodeURIComponent('Premium')}`;
    window.location.href = `/confirmation${queryParams}`;
  })
  .catch((error) => {
    setFailureMessage8('Failed to submit booking. Please try again.');
  });
    }).catch((error) => {
      setFailureMessage8('Failed to submit booking. Please try again.');
    });
  }).catch((error) => {
    setFailureMessage8('Failed to submit booking. Please try again.');
  });
  };

  
  //payment gateway
  const [successMessage9,setSuccessMessage9]= useState('');
  const [failureMessage9,setFailureMessage9]= useState('');
  
  const handleSubmit9=()=>{
    
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString('en-IN');
  
    
    firebase.database().ref('bookingId').once('value', (snapshot) => {
      const currentBookingId = snapshot.val() || 0; // Get current booking ID or default to 0
      const newBookingId = currentBookingId + 1; // Increment the booking ID
  
      const currentUser = firebase.auth().currentUser;
  
      if (!currentUser) {
        alert('Please log in to make a booking.');
        return; // Exit the function if user is not logged in
      }
  
      const user_id = currentUser ? currentUser.uid : '';
      const user_email = currentUser ? currentUser.email : '';
      const user_mobile = currentUser ? currentUser.phoneNumber :''; // You need to implement fetching user mobile number
      const user_name = currentUser ? currentUser.displayName :''; // You need to implement fetching user name
  
      firebase.database().ref('bookingId').set(newBookingId).then(() => {
  
        firebase.database().ref('bookingcards').push({
          booking_Id: newBookingId,
          booking_DateTime : formattedDateTime,
          Booking_Status: 'Pending',
          Booking_Price: 'Rs.8000',
          Booking_Category: 'Platinum',
          user_id,
          user_email,
          user_mobile,
          user_name
  }) 
  .then(() => {
    const queryParams=`?categoryName=${encodeURIComponent('Platinum')}`;
    window.location.href = `/confirmation${queryParams}`;
  })
  .catch((error) => {
    setFailureMessage7('Failed to submit booking. Please try again.');
  });
    }).catch((error) => {
      setFailureMessage7('Failed to submit booking. Please try again.');
    });
  }).catch((error) => {
    setFailureMessage7('Failed to submit booking. Please try again.');
  });
  };
  

  useEffect(() => {
    const heading = document.querySelector('.home-slider h1');

    if (heading) {
      anime({
        targets: heading,
        loop: true,
        direction: 'alternate',
        color: ['#000', '#fff'],
        easing: 'easeInOutSine',
        duration: 700,
        delay: (el, i) => i * 500,
      });
    }
  }, []);

  
  useEffect(()=>{
    document.title="Home | Active Care Physiotherapy & Sports Injury Clinic"
  },[]);

  const CustomInput = ({ value, onClick }) => (
    <div className="date-picker-custom-input" onClick={onClick}>
      <input 
        type="text" 
        value={value} 
        readOnly 
        className="form-control"
        placeholder="Select Date"
      />
      <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
    </div>
  );
  

    return (
        <>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700"
    rel="stylesheet"
  />
  <link rel="stylesheet" href="css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="css/animate.css" />
  <link rel="stylesheet" href="css/owl.carousel.min.css" />
  <link rel="stylesheet" href="css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="css/magnific-popup.css" />
  <link rel="stylesheet" href="css/aos.css" />
  <link rel="stylesheet" href="css/ionicons.min.css" />
  <link rel="stylesheet" href="css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="css/jquery.timepicker.css" />
  <link rel="stylesheet" href="css/flaticon.css" />
  <link rel="stylesheet" href="css/icomoon.css" />
  <link rel="stylesheet" href="css/style.css" />
  <header>
  <nav
    className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
    id="ftco-navbar"
  >
    <div className="container">
    <img className="icoimage" src={webicon} alt="Website Icon" style={{ width: '80px' }} />
    <div className='column'>  
    
      <a className="navbar-brand" href="/" style={{color:"black"}}>
      
              <span className="desktop-view">
              ACTIVECARE PHYSIOTHERAPY & SPORTS INJURY CLINIC
              <br />
              <span className="slogan">Your Pain Stops Here</span>
            </span>
             <span className="mobile-view">
              ActiveCare Physiotherapy<br />&<br />Sports Injury Clinic
              <br />
              <span className="slogan">Your Pain Stops Here</span>
            </span>
      </a>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ftco-nav"
        aria-controls="ftco-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FaBars />
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto" >
          <li className="nav-item active" >
            <a href="/" className="nav-link" style={{color:"#A890FE"}}>
              Home
            </a>
          </li>
          {/*<li className="nav-item">
            <a href="/pharmacy" className="nav-link">
              Pharmacy
            </a>
          </li>*/}
          <li className="nav-item" >
            <a href="/about" className="nav-link" style={{color:"black"}}>
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="/services" className="nav-link" style={{color:"black"}}>
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="/doctors" className="nav-link" style={{color:"black"}}>
              Doctors
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" className="nav-link" style={{color:"black"}}>
              Contact
            </a>
          </li>
          <li className="nav-item cta">
            <a
             
              className="nav-link"
              data-toggle="modal"
              data-target="#modalRequest"
              onClick={() => setModalVisible(true)}
            >
              <span >Appointment</span>
            </a>
          </li>
          <div className="quote_btn-container">
          
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: '#EA8D8D' }}
        />
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10px\\', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link>
              {isAdmin &&<Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>}
              {isAdmin &&<Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>} 
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </div>
        </ul>
      </div>
    </div>
  </nav>
  </header>
  <Loader/>
  {/* END nav */}
  <section className="home-slider">
  <OwlCarousel className="owl-theme home-slider" loop autoplay autoplayTimeout={4000} items={1} nav={false} dots={false}>
    <div
      className="slider-item"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div
          className="row slider-text align-items-center"
          data-scrollax-parent="true"
        >
          <div
            className="col-md-6 col-sm-12 ftco-animate"
            data-scrollax=" properties: { translateY: '70%' }"
          >
            <h1
              className="mb-4"
              data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
            >
             Discover Freedom Through Movement with Physiotherapy
            </h1>
            <p
              className="mb-4"
              data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
            >
              Experience the transformative power of physiotherapy and unlock your body's full potential.
            </p>
            <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
             
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* Our emergency physiotherapy services provide immediate relief and support for acute musculoskeletal injuries and conditions. */}
</OwlCarousel>
  </section><br></br><br></br><br></br><br></br>
  <section className="ftco-intro">
    <div className="container">
      <div className="row no-gutters">
      
        <div className="col-md-3  color-1 p-4 ">
          <h3 className="mb-4"> <FontAwesomeIcon icon={faCalendarDay} /> Flexible Appointment</h3>
          <p style={{textAlign:"justify"}}>We are open to appointment Outside working hours.With Prior appointments from 7 AM to 10 AM and 8 PM to 10 PM.On all working days</p>
          <span className="phone-number">7447447306,7092550824</span>
        </div>
       
        <div className="col-md-3 color-2 p-4">
          <h3 className="mb-4"><FontAwesomeIcon icon={faClock} /> Opening Hours</h3>
          
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Monday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Tuesday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Wednesday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Thursday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Friday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Saturday</span>
            <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
          </p>
          <p className="openinghours d-flex justify-content-between">
            <span className="day1">Sunday</span>
            <span className="time">With Prior Appointment <br></br> +91 7447447306</span>
            
          </p>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 color-3 p-4">
          <h3 className="mb-2"><FontAwesomeIcon icon={faCalendarCheck} /> Make an Appointment</h3>
          <form onSubmit={handleSubmit3} className="appointment-form">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="select-wrap">
                    <div className="icon">
                      <span className="ion-ios-arrow-down" />
                    </div>
                    <select
                      name=""
                      className="form-control wide"
                      id="inputDoctorName"
                      value={formData2.Appointmenttab_doctorname}
                      onChange={handleDoctorNameChange1}
                      style={{fontSize:"14px"}}
                      >
                    
                        <option style={{ color: 'black' }}>Dr.Senthil Nathan</option>
                    
                    </select>
                  </div>
                </div>
              </div>
              
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="icon">
                    <span className="icon-user" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="Appointmenttab_name"
                    value={formData2.Appointmenttab_name} onChange={handleInputChange1}
                    placeholder="Name"
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="icon">
                    <span className="icon-paper-plane" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    name="Appointmenttab_email"
                    value={formData2.Appointmenttab_email} onChange={handleInputChange1}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="icon">
                    <span className="icon-phone2" />
                  </div>
                  <input
                    type="tel"
                    className="form-control"
                    name="Appointmenttab_phone"
                    value={formData2.Appointmenttab_phone} onChange={handleInputChange1}
                    placeholder="Phone"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group foalign">
                
                <DatePicker
                  selected={formData2.Appointmenttab_date}
                  onChange={(date) => setFormData2({ ...formData2, Appointmenttab_date: date })}
                  dateFormat="dd-MM-yyyy"
                  className="form-control cal"
                  placeholderText="Select Date"
                  minDate={new Date()}
                  required
                  
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                <select className="form-control" name="Appointmenttab_time" value={formData2.Appointmenttab_time} onChange={handleTimeChange1} placeholder="Select Time" required>
                <option value="" style={{color: "black"}}>Select Time</option>
                  {timeOptions2.length > 0
                    ? timeOptions2.map((time, index) => (
                        <option key={index} value={time} style={{color: "black"}}>{time}</option>
                      ))
                    : defaultTimeOptions.map((time, index) => (
                        <option key={index} value={time} style={{color: "black"}}>{time}</option>
                ))}
                </select>
                </div>
              </div>
            
            </div><br></br>
            <div className="form-group d-flex justify-content-center">
              <button type="submit" className="btn btn-secondary" style={{alignItems:"center"}}>Submit</button>
            </div>
            {successMessage3 && <p className="text-success" style={{color:"#2118c9", textAlign:"center"}}>{successMessage3}</p>}
          </form>
        </div>
      </div>
    </div>
  </section><br></br><br></br>

  <section className="ftco-intro1">
  <div className="container">
    <div className="row justify-content-center mb-5 pb-5">
      <div className="col-md-7 text-center heading-section ftco-animate">
        <h2 className="mb-3">{aboutContent1.title}</h2>
       
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 d-flex">
        <div className="card mb-4 shadow-sm w-100">
        
          <div className="about-wrap card-body">
          <p className=' aboutdes mb-4 align-items-center'>{aboutContent1.description}</p>
            <div className="services-grid" style={{paddingTop:"10px"}}>
             
              {aboutContent1.services.map((service, index) => (
                <div key={index} className="list-services d-flex ftco-animate mb-3 service-item">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="icon-check2" />
                  </div>
                  <div className="text ml-3">
                    <h3 style={{ fontSize: "20px", textAlign: "justify" }}>{service.title}</h3>
                    <p style={{ fontSize: "16px", textAlign: "justify" }}>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  <section className="ftco-section ftco-services">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
          <h2 className="mb-2">{serviceContent.title}</h2>
          <p>
          {serviceContent.description}
          </p>
        </div>
      </div>
      <div className="row">
      
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="100" height="100" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#A890FE" stroke="none">
              <path d="M1222 4613 c-7 -3 -19 -18 -28 -35 -13 -26 -14 -57 -4 -256 18 -375
              69 -821 99 -862 38 -52 101 -50 137 5 16 25 16 35 -4 198 -38 307 -53 473 -60
              691 -8 208 -9 216 -32 241 -23 24 -72 33 -108 18z"/>
              <path d="M1641 4601 c-11 -11 -23 -35 -26 -53 -3 -18 -4 -441 -3 -940 l3 -906
              24 -26 c34 -36 88 -36 122 0 l24 26 0 933 0 933 -24 26 c-31 33 -91 36 -120 7z"/>
              <path d="M2071 4596 c-12 -13 -24 -38 -26 -57 -11 -78 -87 -1899 -81 -1923 9
              -36 66 -63 103 -50 64 22 58 -26 102 954 53 1172 51 1043 22 1074 -33 35 -88
              36 -120 2z"/>
              <path d="M909 3391 c-16 -17 -29 -40 -29 -54 0 -16 31 -68 84 -139 l84 -113
              -52 -5 c-103 -10 -139 -65 -92 -143 48 -80 261 -352 287 -366 51 -28 119 14
              119 74 0 24 -19 58 -80 138 -44 59 -80 108 -80 110 0 3 26 7 57 9 46 4 61 10
              80 32 25 30 29 65 11 99 -25 47 -277 375 -293 381 -35 13 -67 5 -96 -23z"/>
              <path d="M1525 2510 c-195 -33 -361 -154 -445 -323 -91 -185 -91 -359 0 -545
              72 -146 210 -259 375 -308 72 -21 237 -24 305 -5 141 39 283 144 362 265 122
              188 121 455 -2 642 -83 126 -226 230 -361 263 -67 16 -176 21 -234 11z m277
              -212 c92 -46 157 -112 201 -206 30 -63 32 -74 32 -177 0 -103 -2 -114 -31
              -176 -76 -159 -217 -250 -389 -250 -130 -1 -222 39 -313 135 -183 194 -148
              494 76 648 78 54 135 69 248 65 93 -2 106 -5 176 -39z"/>
              <path d="M1505 2146 c-91 -43 -144 -126 -145 -225 0 -74 17 -124 59 -172 76
              -90 208 -115 312 -61 186 97 186 357 0 454 -70 37 -153 38 -226 4z m166 -175
              c36 -36 37 -70 3 -110 -51 -61 -144 -26 -144 54 0 79 84 112 141 56z"/>
              <path d="M2435 1688 c-31 -18 -44 -40 -45 -76 0 -50 37 -82 95 -82 46 0 78
              -19 263 -157 l32 -24 -41 -47 c-23 -26 -52 -69 -65 -97 -22 -47 -24 -61 -24
              -242 0 -148 -3 -193 -12 -192 -7 0 -85 12 -173 27 l-160 28 -5 242 c-5 225 -6
              243 -25 261 -34 33 -86 31 -120 -4 l-25 -24 0 -231 0 -230 -312 2 -312 3 -37
              150 c-42 165 -54 185 -116 185 -43 0 -83 -37 -83 -77 0 -15 16 -89 35 -163 19
              -74 33 -135 32 -136 -11 -6 -289 -114 -295 -114 -5 0 -67 74 -139 164 l-131
              164 119 198 c73 123 119 209 119 226 0 15 -11 39 -24 54 -28 34 -84 38 -115
              10 -11 -10 -82 -122 -157 -248 -114 -194 -135 -235 -130 -259 3 -17 74 -115
              167 -232 209 -261 215 -267 256 -267 18 0 128 38 245 85 l213 85 363 0 363 0
              269 -45 c269 -45 402 -55 455 -35 29 11 58 69 48 97 -15 43 -45 63 -95 63
              l-48 0 0 180 c0 202 2 209 69 274 l40 39 53 -37 c155 -111 130 -101 398 -145
              146 -24 287 -54 348 -73 93 -29 103 -34 97 -53 -14 -47 -45 -202 -45 -227 0
              -33 7 -32 -120 -13 -108 16 -137 12 -164 -27 -34 -48 -11 -109 47 -127 17 -5
              105 -19 194 -31 138 -19 196 -22 390 -18 246 4 266 8 331 63 102 85 104 255 5
              346 -42 40 -61 47 -423 167 -326 108 -342 112 -590 155 l-256 43 -303 217
              -304 217 -66 0 c-36 0 -75 -6 -86 -12z m1740 -848 c171 -56 201 -74 190 -119
              -11 -48 -25 -51 -228 -51 l-189 0 7 48 c14 99 37 183 48 179 7 -2 84 -28 172
              -57z"/>
              <path d="M3165 725 c-37 -36 -34 -86 6 -119 40 -34 74 -33 110 3 56 57 23 141
              -56 141 -25 0 -44 -8 -60 -25z"/>
              </g>
              </svg>


            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Arthritis Treatment</h3>
              <p className='text-justify'>
              Arthritis treatment typically aims to manage symptoms, slow down the progression of the disease, and improve quality of life. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#A890FE" stroke="none">
            <path d="M1597 4948 c-18 -18 -29 -54 -51 -179 -16 -86 -26 -167 -23 -181 8
            -29 38 -43 110 -53 31 -4 47 -11 44 -18 -2 -7 -15 -45 -28 -85 -21 -67 -22
            -76 -8 -100 19 -34 77 -43 101 -15 8 10 35 76 58 147 l42 128 -19 27 c-16 20
            -32 28 -79 35 -32 4 -64 9 -70 11 -9 3 -8 22 2 72 8 37 17 98 20 134 6 62 5
            67 -17 83 -32 22 -56 20 -82 -6z"/>
            <path d="M819 4728 c-38 -29 -32 -52 46 -205 42 -81 83 -154 92 -160 27 -21
            62 -15 114 17 l49 30 13 -33 c8 -17 22 -56 31 -85 21 -61 49 -82 94 -66 51 18
            52 51 2 196 -27 78 -51 133 -61 139 -31 16 -67 10 -120 -21 -28 -17 -54 -28
            -58 -25 -3 4 -31 55 -61 113 -53 103 -56 107 -90 110 -19 2 -42 -3 -51 -10z"/>
            <path d="M2070 4567 c-34 -17 -38 -40 -39 -222 l-1 -159 -37 -38 c-61 -62 -45
            -128 32 -128 26 0 43 11 88 59 l57 60 0 196 c0 195 0 196 -25 220 -27 28 -39
            30 -75 12z"/>
            <path d="M2993 4570 c-39 -16 -43 -41 -43 -240 l0 -191 58 -61 c152 -160 390
            -329 702 -498 281 -152 363 -236 510 -530 231 -460 507 -1193 725 -1920 21
            -69 45 -133 53 -142 34 -40 112 -11 112 42 0 42 -213 703 -347 1075 -205 570
            -457 1118 -589 1283 -104 130 -189 196 -404 312 -265 144 -458 274 -603 410
            l-76 71 -3 179 c-2 143 -6 182 -18 194 -21 20 -51 26 -77 16z"/>
            <path d="M2519 4241 l-24 -19 -3 -1940 -2 -1939 21 -27 c28 -35 70 -35 98 0
            l21 27 -2 1939 -3 1940 -24 19 c-13 10 -31 19 -41 19 -10 0 -28 -9 -41 -19z"/>
            <path d="M1405 4166 c-81 -21 -145 -56 -205 -115 -170 -168 -170 -444 0 -611
            253 -249 669 -113 732 240 30 174 -68 368 -228 448 -101 50 -199 63 -299 38z
            m232 -154 c54 -28 106 -80 135 -135 33 -61 33 -203 0 -264 -79 -148 -253 -206
            -400 -132 -106 52 -162 144 -162 264 0 120 56 211 163 265 57 29 68 31 142 28
            56 -3 92 -11 122 -26z"/>
            <path d="M350 4100 c-25 -25 -26 -61 -2 -88 9 -11 75 -57 145 -102 156 -99
            175 -101 224 -25 18 27 34 54 36 60 2 5 32 -16 68 -48 49 -43 71 -57 95 -57
            57 0 85 64 47 108 -10 11 -58 54 -106 96 -63 54 -96 76 -115 76 -37 0 -44 -7
            -82 -70 -19 -30 -35 -57 -36 -59 -2 -2 -49 26 -105 63 -108 70 -137 78 -169
            46z"/>
            <path d="M993 3430 c-99 -40 -394 -654 -636 -1325 -137 -380 -347 -1032 -347
            -1077 0 -51 78 -79 112 -40 8 9 32 73 53 142 59 198 210 655 292 885 190 532
            450 1114 568 1270 53 70 58 104 19 134 -30 23 -30 23 -61 11z"/>
            <path d="M1322 2684 c-34 -23 -28 -59 33 -189 31 -64 58 -130 62 -145 3 -19
            -11 -74 -41 -165 -159 -466 -365 -874 -677 -1335 -66 -97 -119 -184 -119 -193
            0 -25 45 -67 71 -67 31 0 62 38 191 232 255 384 431 716 574 1085 32 84 62
            153 65 153 7 0 55 -306 74 -470 50 -446 41 -901 -26 -1245 -25 -132 -25 -160
            3 -179 63 -45 99 -4 126 145 48 254 56 358 56 709 1 358 -9 496 -60 830 -64
            422 -100 554 -209 771 -42 82 -73 98 -123 63z"/>
            <path d="M3722 2693 c-33 -13 -154 -296 -181 -426 -31 -144 -71 -377 -71 -413
            0 -37 26 -64 63 -64 48 0 63 27 82 154 10 64 22 116 26 116 4 0 15 -22 24 -49
            9 -27 24 -56 35 -66 25 -23 69 -16 93 16 l19 27 -58 171 c-66 197 -68 169 17
            350 27 57 49 117 49 132 0 43 -52 71 -98 52z"/>
            <path d="M4325 1919 l-96 -82 -17 24 c-31 44 -77 87 -101 94 -20 6 -33 -3 -86
            -58 -81 -85 -131 -147 -139 -173 -9 -31 28 -74 65 -74 30 0 34 3 107 83 23 26
            44 47 45 47 2 0 19 -21 38 -46 38 -52 68 -68 102 -55 12 5 77 57 145 116 105
            91 122 110 122 135 0 36 -31 70 -64 70 -16 0 -58 -28 -121 -81z"/>
            <path d="M3376 1650 c-279 -89 -387 -439 -208 -675 46 -61 145 -129 219 -151
            90 -26 221 -16 303 22 95 45 162 110 207 202 37 75 38 78 38 192 0 110 -2 119
            -32 180 -17 36 -48 84 -67 106 -105 123 -300 175 -460 124z m259 -139 c56 -25
            121 -91 148 -150 30 -64 30 -178 1 -242 -31 -66 -78 -114 -142 -146 -74 -36
            -182 -39 -253 -6 -60 27 -118 85 -149 148 -33 69 -33 180 -1 247 39 81 118
            146 206 169 45 12 141 2 190 -20z"/>
            <path d="M4206 1364 c-69 -13 -126 -30 -137 -40 -25 -22 -24 -69 1 -94 24 -24
            34 -24 120 -5 95 21 90 24 90 -53 0 -106 1 -107 207 -98 180 8 207 15 219 61
            4 16 0 35 -11 51 -16 25 -17 25 -148 23 l-132 -2 -5 77 c-4 62 -9 81 -24 92
            -25 18 -35 17 -180 -12z"/>
            <path d="M4242 984 c-44 -30 -31 -67 81 -230 112 -162 135 -181 181 -151 53
            35 47 55 -64 220 -57 84 -110 158 -118 165 -21 16 -53 15 -80 -4z"/>
            <path d="M3915 868 c-28 -16 -35 -28 -35 -65 0 -39 17 -52 113 -83 37 -12 67
            -25 65 -29 -2 -4 -16 -33 -33 -64 -28 -53 -29 -59 -15 -83 18 -32 281 -174
            323 -174 48 0 79 48 61 95 -4 9 -55 41 -115 71 -60 30 -109 59 -109 64 0 6 14
            34 30 63 61 108 56 112 -255 213 -5 2 -19 -2 -30 -8z"/>
            <path d="M3441 673 c-23 -15 -23 -20 -17 -98 7 -95 43 -314 59 -364 7 -20 24
            -42 40 -51 24 -13 30 -13 55 0 24 14 27 22 27 65 -1 28 -10 106 -22 175 -12
            69 -25 154 -29 189 -9 93 -54 126 -113 84z"/>
            </g>
            </svg>
 
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Back Pain</h3>
              <p className='text-justify'>
              Back pain can result from various causes, including muscle strains, ligament sprains, poor posture, injury, or underlying medical conditions.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#A890FE" stroke="none">
            <path d="M2326 4974 c-97 -23 -185 -73 -257 -144 -81 -80 -123 -154 -151 -261
            -89 -353 175 -691 538 -691 159 0 283 51 394 162 111 111 162 235 162 394 0
            163 -60 297 -183 410 -134 124 -324 173 -503 130z m277 -158 c116 -48 197
            -128 238 -237 125 -329 -201 -655 -530 -530 -111 42 -197 131 -242 250 -17 46
            -20 72 -17 151 5 115 32 183 104 261 87 92 170 127 304 128 68 0 97 -4 143
            -23z"/>
            <path d="M3030 4010 c-11 -11 -20 -33 -20 -50 0 -17 9 -39 20 -50 20 -20 33
            -20 640 -20 673 0 664 1 708 -55 42 -53 22 -143 -38 -179 -25 -14 -127 -17
            -987 -25 l-958 -10 -22 -26 c-29 -33 -29 -57 0 -90 l22 -26 797 0 c880 1 848
            4 888 -61 41 -65 3 -167 -69 -183 -14 -3 -388 -9 -830 -14 -785 -10 -805 -10
            -824 -30 -25 -25 -26 -74 -2 -101 17 -18 30 -20 180 -20 l162 0 7 -152 c11
            -249 16 -298 37 -319 18 -18 45 -19 575 -19 375 0 569 -4 599 -11 59 -15 129
            -77 150 -133 16 -41 16 -91 3 -708 -12 -588 -16 -667 -31 -695 -53 -99 -205
            -110 -270 -19 -22 31 -22 34 -27 526 -6 485 -6 496 -28 552 -27 67 -97 148
            -159 185 -84 49 -124 53 -581 53 l-423 0 -24 -25 c-32 -31 -32 -69 0 -100 l24
            -25 434 0 435 0 53 -28 c39 -20 62 -41 84 -77 l30 -48 3 -765 c2 -748 2 -766
            -18 -806 -67 -138 -298 -118 -339 29 -7 27 -11 243 -11 665 0 344 -4 630 -8
            636 -18 28 -67 32 -507 44 -480 12 -531 17 -617 61 -67 34 -108 69 -155 132
            -82 112 -81 108 -103 828 -14 449 -16 654 -9 698 14 88 43 161 110 272 33 53
            59 108 59 121 0 51 -62 84 -110 58 -22 -12 -109 -153 -149 -242 -44 -98 -61
            -190 -61 -336 0 -170 28 -1108 35 -1195 14 -151 93 -299 215 -400 53 -44 164
            -99 235 -117 30 -7 220 -17 485 -25 239 -7 436 -13 438 -14 1 0 2 -271 2 -601
            0 -588 0 -600 21 -657 29 -77 96 -143 175 -173 49 -18 74 -21 149 -18 106 5
            169 32 228 97 72 80 87 142 87 368 0 84 2 153 5 153 4 0 20 -8 38 -17 51 -27
            179 -23 242 7 68 33 129 99 154 166 20 52 22 92 36 724 12 594 12 676 -1 727
            -27 105 -94 186 -197 241 l-52 27 -552 3 -551 3 -6 117 c-3 64 -8 143 -10 175
            l-4 57 131 1 c73 1 344 4 602 8 l470 6 50 27 c123 65 180 196 138 319 l-19 56
            69 6 c39 3 88 15 113 27 86 42 154 150 154 245 0 96 -62 194 -152 239 l-52 26
            -648 0 c-635 0 -648 0 -668 -20z"/>
            <path d="M1267 2452 c-325 -176 -559 -439 -651 -732 -99 -314 -24 -655 206
            -934 248 -300 653 -507 1138 -582 160 -25 500 -25 660 0 135 20 266 50 315 70
            39 17 54 53 39 95 -16 44 -50 48 -181 17 -247 -57 -539 -72 -780 -41 -636 83
            -1136 431 -1265 882 -30 103 -32 314 -5 413 22 82 83 210 138 292 92 136 300
            313 470 398 73 38 89 54 89 95 0 35 -36 75 -69 75 -9 0 -55 -22 -104 -48z"/>
            </g>
            </svg>


            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Balance Exercise Therapy</h3>
              <p className='text-justify'>
              Balance exercise therapy, also known as balance training, is a form of physical therapy aimed at improving balance, stability, and coordination.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#A890FE" stroke="none">
              <path d="M2759 4484 c-16 -20 -22 -125 -49 -894 -11 -327 -25 -680 -30 -785
              -24 -473 -2 -596 130 -725 46 -44 92 -73 241 -147 105 -53 218 -119 264 -153
              109 -82 323 -286 402 -384 36 -45 86 -96 111 -113 82 -59 231 -107 587 -189
              194 -44 230 -61 256 -117 20 -46 25 -127 9 -158 -13 -23 -72 -54 -123 -63 -22
              -4 -116 -3 -210 3 -95 6 -309 15 -476 21 -167 6 -412 17 -545 26 -358 24 -833
              2 -1171 -53 -88 -15 -200 -27 -250 -27 -78 -1 -98 3 -148 26 -110 52 -197 170
              -238 325 -26 98 -28 297 -5 488 25 209 30 348 16 515 -6 85 -74 650 -150 1254
              -119 949 -140 1101 -156 1113 -26 19 -52 16 -68 -9 -13 -21 -4 -111 100 -923
              193 -1524 199 -1594 159 -1920 -11 -91 -19 -213 -18 -310 0 -144 3 -169 27
              -250 36 -119 86 -206 160 -281 134 -134 254 -158 537 -109 332 58 944 86 1249
              56 133 -13 962 -46 1125 -45 93 0 118 4 162 23 46 21 122 87 123 109 1 4 5 27
              10 52 16 85 -18 196 -77 252 -45 43 -108 65 -320 113 -262 60 -363 89 -450
              131 -61 29 -83 47 -146 122 -104 122 -315 324 -422 405 -57 43 -156 101 -272
              159 -202 101 -251 139 -290 227 -41 91 -48 183 -35 459 7 136 21 506 32 822
              11 316 24 654 29 750 8 146 7 179 -5 198 -17 26 -56 29 -75 6z"/>
              <path d="M644 3175 c-9 -14 -14 -31 -11 -37 9 -24 279 -343 296 -349 19 -8 61
              6 61 20 0 5 3 16 6 24 3 9 -49 80 -141 191 -157 189 -177 203 -211 151z"/>
              <path d="M4292 3093 c-23 -9 -262 -285 -262 -302 0 -31 24 -48 123 -91 53 -24
              97 -45 97 -47 0 -3 -34 -53 -75 -111 -74 -106 -89 -144 -63 -170 26 -26 57
              -13 96 41 104 139 172 244 172 263 0 29 -23 46 -120 89 -47 20 -87 38 -89 40
              -2 2 40 52 93 112 100 114 112 140 74 167 -24 17 -26 17 -46 9z"/>
              <path d="M374 2855 c-14 -22 -14 -28 -3 -46 19 -28 437 -249 472 -249 32 0 54
              39 38 69 -12 21 -443 251 -471 251 -11 0 -27 -11 -36 -25z"/>
              <path d="M3567 2683 c-146 -165 -154 -176 -135 -205 8 -12 57 -41 109 -64 54
              -24 94 -48 93 -55 -1 -8 -33 -60 -72 -115 -40 -56 -72 -111 -72 -121 0 -23 30
              -53 52 -53 8 0 24 10 35 23 24 27 176 243 192 275 16 31 -1 60 -47 79 -20 8
              -65 28 -100 44 l-64 28 96 107 c98 110 111 137 80 168 -31 31 -52 17 -167
              -111z"/>
              <path d="M488 2401 c-84 -4 -142 -11 -149 -18 -17 -17 -14 -65 5 -78 17 -13
              295 -10 366 4 45 9 63 50 35 81 -20 22 -4 21 -257 11z"/>
              <path d="M2634 2120 c-12 -5 -32 -23 -44 -40 -29 -41 -159 -144 -220 -175 -52
              -26 -67 -52 -51 -84 21 -37 48 -35 128 11 94 54 267 210 271 247 6 41 -35 61
              -84 41z"/>
              <path d="M1765 1800 c-27 -30 -17 -241 15 -324 31 -81 96 -149 166 -176 60
              -22 202 -28 248 -10 33 12 44 54 21 80 -15 17 -27 18 -101 13 -186 -13 -253
              61 -262 292 -2 66 -9 126 -14 133 -15 18 -53 15 -73 -8z"/>
              <path d="M4200 1050 c-23 -23 -25 -39 -7 -63 13 -18 227 -71 239 -59 4 4 10
              26 14 48 5 36 3 43 -12 48 -18 5 -204 46 -211 46 -2 0 -12 -9 -23 -20z"/>
              </g>
              </svg>



            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Foot and Ankle Pain</h3>
              <p  className='text-justify'>
              Foot and ankle pain can arise from various causes, including injuries, overuse, medical conditions, or structural issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-wrap mt-5">
      <div className="row d-flex no-gutters">
        <div
          className="col-md-6 img"
          style={{ backgroundImage: `url(${abtImg})` }}
        ></div>
        <div className="col-md-6 d-flex">
          <div className="about-wrap">
            <div className="heading-section heading-section-white mb-5 ftco-animate">
              <h2 className="mb-2">{aboutContent.title}</h2>
              <p style={{textAlign:"justify"}}>
                {aboutContent.description}
              </p>
            </div>
            {aboutContent.services.map((service, index) => (
            <div className="list-services d-flex ftco-animate">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="icon-check2" />
              </div>
              <div className="text">
                <h3>{service.title}</h3>
                <p style={{textAlign:"justify"}}>
                {service.description}
                </p>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*doctor section*/}
  <section className="ftco-section doctor">
  <div className="container">
    <div className="row justify-content-center mb-5 pb-5">
      <div className="col-md-12 text-center heading-section ftco-animate">
        <h2 className="mb-3">{dentistData?.doctor_title}</h2>
        <p >
          {dentistData?.doctor_description}
        </p>
      </div>  
    </div>

    <div className="row justify-content-center">
      {doctorData.map(doctor => (
        <div key={doctor.doctor_id} className="col-lg-4 col-md-6 mb-sm-4 ftco-animate"> {/* Adjust column size based on screen size */}
          <div className="staff" style={{ maxWidth: '300px', margin: '0 auto' }}>
            <div className="img mb-4" style={{ backgroundImage: `url(${doctor.imageUrl})` }} />
            <div className="info text-center" style={{ height: "250px" }}>
              <h3>
                <a href="teacher-single.html">{doctor.doctor_name}</a>
              </h3>
              <span className="position">{doctor.doctor_specialization}</span>
              <div className="text">
                <p className='text-justify'>
                  {doctor.doctor_description}
                </p>
                <ul className="ftco-social">
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="icon-twitter" />
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="icon-facebook" />
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="icon-instagram" />
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a href="#">
                      <span className="icon-google-plus" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

  </div>
</section>
 <section
    className="ftco-section ftco-counter img"
    id="section-counter"
    style={{ backgroundImage: `url(${bgwhite})` }}
    data-stellar-background-ratio="0.5"
  >
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-md-3 aside-stretch py-5">
          <div className=" heading-section heading-section-white ftco-animate pr-md-4">
            <h2 className="mb-3">{achievementData1?.Achievement_title}</h2>
            <p style={{textAlign:"justify"}}>
            {achievementData1?.Achievement_description}
            </p>
          </div>
        </div>
        {/* <div className="col-md-9 py-5 pl-md-5">
          <div className="row">
          {achievements.map((achievement, index) => (
            <div key={index} className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                {achievement.Achievement_icon === 'flower' && <IoMdFlower style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'happy' && <IoMdHappy style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'star' && <IoMdStar style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'medkit' && <IoMdMedkit style={{ color: '#A890FE', fontSize: '60px' }} />}
                </div>
                <strong className="number" style={{ color: counts[achievement.Achievement_id] === 0 ? '#A890FE' : '#A890FE' }}>
                {counts[achievement.Achievement_id] !== undefined ? 
                      (achievement.Achievement_id !== 2 ? `${counts[achievement.Achievement_id]}+` : counts[achievement.Achievement_id])
                      : '0'}
                </strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>{achievement.Achievement_name}</span>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div> */}
        <div className="col-md-9 py-5 pl-md-5">
          <div className="row">
        
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdFlower style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>15+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Years of Experience</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdStar style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>5</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Star Rated Physiotherapist</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdHappy style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>5000+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Number of Happy Patients Treated</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdMedkit style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>800+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>New Visits every year</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section><br></br>
  {/*<section className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
          <h2 className="mb-3">Our Best Pricing</h2>
          <p>
            A small river named Duden flows by their place and supplies it with
            the necessary regelialia.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 ftco-animate">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">Basic</h3>
              <p>
                <span className="price">Rs.2000</span>{" "}
                <span className="per">/ session</span>
              </p>
            </div>
            <ul>
              <li>Diagnostic Services</li>
              <li>Professional Consultation</li>
              <li>Tooth Implants</li>
              <li>Surgical Extractions</li>
              <li>Teeth Whitening</li>
            </ul>
            <p className="button text-center">
              <a
                href="#"
                className="btn btn-primary btn-outline-primary px-4 py-3"
                onClick={handleSubmit6}
              >
                Order now
              </a>
            </p>
            {successMessage6 && <div className="alert alert-success mt-3" role="alert">{successMessage6}</div>}
        {failureMessage6 && <div className="alert alert-danger mt-3" role="alert">{failureMessage6}</div>}
          </div>
        </div>
        <div className="col-md-3 ftco-animate">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">Standard</h3>
              <p>
                <span className="price">Rs.4000</span>{" "}
                <span className="per">/ session</span>
              </p>
            </div>
            <ul>
              <li>Diagnostic Services</li>
              <li>Professional Consultation</li>
              <li>Tooth Implants</li>
              <li>Surgical Extractions</li>
              <li>Teeth Whitening</li>
            </ul>
            <p className="button text-center">
              <a
                href="#"
                className="btn btn-primary btn-outline-primary px-4 py-3"
                onClick={handleSubmit7}
              >
                Order now
              </a>
            </p>
            {successMessage7 && <div className="alert alert-success mt-3" role="alert">{successMessage7}</div>}
        {failureMessage7 && <div className="alert alert-danger mt-3" role="alert">{failureMessage7}</div>}
          </div>
        </div>
        <div className="col-md-3 ftco-animate">
          <div className="pricing-entry active pb-5 text-center">
            <div>
              <h3 className="mb-4">Premium</h3>
              <p>
                <span className="price">Rs.6000</span>{" "}
                <span className="per">/ session</span>
              </p>
            </div>
            <ul>
              <li>Diagnostic Services</li>
              <li>Professional Consultation</li>
              <li>Tooth Implants</li>
              <li>Surgical Extractions</li>
              <li>Teeth Whitening</li>
            </ul>
            <p className="button text-center">
              <a
                href="#"
                className="btn btn-primary btn-outline-primary px-4 py-3"
                onClick={handleSubmit8}
              >
                Order now
              </a>
            </p>
            {successMessage8 && <div className="alert alert-success mt-3" role="alert">{successMessage8}</div>}
        {failureMessage8 && <div className="alert alert-danger mt-3" role="alert">{failureMessage8}</div>}
          </div>
        </div>
        <div className="col-md-3 ftco-animate">
          <div className="pricing-entry pb-5 text-center">
            <div>
              <h3 className="mb-4">Platinum</h3>
              <p>
                <span className="price">Rs.8000</span>{" "}
                <span className="per">/ session</span>
              </p>
            </div>
            <ul>
              <li>Diagnostic Services</li>
              <li>Professional Consultation</li>
              <li>Tooth Implants</li>
              <li>Surgical Extractions</li>
              <li>Teeth Whitening</li>
            </ul>
            <p className="button text-center">
              <a
                href="#"
                className="btn btn-primary btn-outline-primary px-4 py-3"
                onClick={handleSubmit9}
              >
                Order now
              </a>
            </p>
            {successMessage8 && <div className="alert alert-success mt-3" role="alert">{successMessage8}</div>}
        {failureMessage8 && <div className="alert alert-danger mt-3" role="alert">{failureMessage8}</div>}
          </div>
        </div>
      </div>
    </div>
  </section>*/}
  <section className="ftco-section-parallax">
    <div className="parallax-img d-flex align-items-center">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 text-center heading-section heading-section-white ftco-animate">
            <h2>{newsletterContent.title}</h2>
            <p style={{ textAlign: 'justify' }}>
              {newsletterContent.description}
            </p>
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-md-8">
                <form onSubmit={handleSubmit2} className="subscribe-form">
                  <div className="form-group d-flex">
                    <input
                      type="text"
                      value={email2}
                      onChange={handleChange2}
                      className="form-control"
                      placeholder="Enter email address"
                    />
                    <input
                      type="submit"
                      defaultValue="Subscribe"
                      className="submit px-3"
                    />
                  </div>
                  {successMessage2 && <div className="alert alert-success">{successMessage2}</div>}
        ``````````{errorMessage2 && <div className="alert alert-danger">{errorMessage2}</div>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*testimony*/}
  <section className="ftco-section testimony-section bg-light">
  <div className="container">
    <div className="row justify-content-center mb-5 pb-3">
      <div className="col-md-7 text-center heading-section ftco-animate">
        <h2 className="mb-2">World-Class Physiotherapy at Clinic, Home or Online Choose what works for you!</h2>
        {/* <span className="subheading">Our Happy Patients Say</span> */}
      </div>
    </div>
    <div className="row justify-content-center ftco-animate">
      <div className="col-md-12">
        <Slider {...settings}>
        {testimonialData.map(testimonial => (
            <div key={testimonial.time} className="item" style={{marginRight:"10px"}}>
              <div className="card" style={{ border: "none", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <div className="card-body text-center" style={{ height: "600px" }}>
                  <h5 className="card-title" style={{ fontWeight: 'bold'}}>{testimonial.author_name}</h5>
                  <p className="card-text mt-1 text-justify">{testimonial.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  </div>
</section>
  <section className="ftco-gallery">
    <div className="container-wrap">
      <div className="row no-gutters">
        <div className="col-md-3 ftco-animate">
          <a
            
            className="gallery img d-flex align-items-center"
            style={{ backgroundImage: `url(${gallery1Img})` }}
          >
            <div className="icon mb-4 d-flex align-items-center justify-content-center">
              <span className="icon-search" />
            </div>
          </a>
        </div>
        <div className="col-md-3 ftco-animate">
          <a
            
            className="gallery img d-flex align-items-center"
            style={{ backgroundImage: `url(${gallery2Img})` }}
          >
            <div className="icon mb-4 d-flex align-items-center justify-content-center">
              <span className="icon-search" />
            </div>
          </a>
        </div>
        <div className="col-md-3 ftco-animate">
          <a
           
            className="gallery img d-flex align-items-center"
            style={{ backgroundImage: `url(${gallery3Img})` }}
          >
            <div className="icon mb-4 d-flex align-items-center justify-content-center">
              <span className="icon-search" />
            </div>
          </a>
        </div>
        <div className="col-md-3 ftco-animate">
          <a
           
            className="gallery img d-flex align-items-center"
            style={{ backgroundImage: `url(${gallery4Img})` }}
          >
            <div className="icon mb-4 d-flex align-items-center justify-content-center">
              <span className="icon-search" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section><br></br>
 
  
  <footer className="ftco-footer ftco-bg-dark ftco-section footer">
      <div className="container" style={{marginTop:"20px"}}>
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2" style={{fontWeight:"bold"}}>{brandName || ''}</h2>
              <p style={{color:"black"}}>
                A fully equipped center for effective physiotherapy treatments. Our experienced and clinically versatile physiotherapist are immensely knowledgeable in clinical reasoning and hypothesizing the clinical impressions.
              </p>
            </div>
            <svg className="svg--source" width={0} height={0} aria-hidden="true">
           
                  <symbol id="svg--twitter" viewBox="0 -7 15 30">
            <path d="M15.36 3.434c-0.542 0.241-1.124 0.402-1.735 0.476 0.624-0.374 1.103-0.966 1.328-1.67-0.583 0.346-1.23 0.598-1.917 0.733-0.551-0.587-1.336-0.954-2.205-0.954-1.668 0-3.020 1.352-3.020 3.019 0 0.237 0.026 0.467 0.078 0.688-2.51-0.126-4.735-1.328-6.224-3.155-0.261 0.446-0.41 0.965-0.41 1.518 0 1.048 0.534 1.972 1.344 2.514-0.495-0.016-0.961-0.151-1.368-0.378 0 0.013 0 0.025 0 0.038 0 1.463 1.042 2.683 2.422 2.962-0.253 0.069-0.52 0.106-0.796 0.106-0.194 0-0.383-0.018-0.568-0.054 0.384 1.2 1.5 2.073 2.821 2.097-1.034 0.81-2.335 1.293-3.75 1.293-0.244 0-0.484-0.014-0.72-0.042 1.336 0.857 2.923 1.357 4.63 1.357 5.554 0 8.592-4.602 8.592-8.593 0-0.13-0.002-0.261-0.009-0.39 0.59-0.426 1.102-0.958 1.507-1.563z" />
          </symbol>
           
            <symbol id="svg--google" viewBox="-13 -13 72 72">
              <path
                d="M48,22h-5v-5h-4v5h-5v4h5v5h4v-5h5 M16,21v6.24h8.72c-0.67,3.76-3.93,6.5-8.72,6.5c-5.28,0-9.57-4.47-9.57-9.75
        s4.29-9.74,9.57-9.74c2.38,0,4.51,0.82,6.19,2.42v0.01l4.51-4.51C23.93,9.59,20.32,8,16,8C7.16,8,0,15.16,0,24s7.16,16,16,16
        c9.24,0,15.36-6.5,15.36-15.64c0-1.17-0.11-2.29-0.31-3.36C31.05,21,16,21,16,21z"
              />
            </symbol>
            <symbol id="svg--facebook" viewBox="0 -7 16 30">
              <path d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z" />
            </symbol>
         
            
            <symbol id="svg--youtube" viewBox="-150 -150 800 800">
              <path
                d="M459,61.2C443.7,56.1,349.35,51,255,51c-94.35,0-188.7,5.1-204,10.2C10.2,73.95,0,163.2,0,255s10.2,181.05,51,193.8
              C66.3,453.9,160.65,459,255,459c94.35,0,188.7-5.1,204-10.2c40.8-12.75,51-102,51-193.8S499.8,73.95,459,61.2z M204,369.75v-229.5
              L357,255L204,369.75z"
              />
            </symbol>
          </svg>
          <div className="wrapper">
            <div className="connect">
              <a href="https://www.instagram.com/activecarephysiotherapy_omr/" className="share twitter">
              <i className="fab fa-instagram" style={{width:"30px",height:"10px",fontWeight:"bold",fontSize:"30px",marginTop:"7px",marginLeft:"10px"}}></i>

                <span className="clip">Instagram</span>
              </a>
              <a href="https://maps.app.goo.gl/1sWcVQPMfMHd8cHf7" rel="author" className="share google">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--google" />
                </svg>
                <span className="clip">GOOGLE +</span>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61562412287941" rel="author" className="share facebook">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--facebook" />
                </svg>
                <span className="clip">FACEBOOK</span>
              </a>
            
              <a href="" className="share  youtube">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--youtube" />
                </svg>
                <span className="clip">YOU-TUBE</span>
              </a>
            </div>
          </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center justify-content-md-start">
            <div className="ftco-footer-widget1 footer-duplicate mb-4">
              <h2 className="ftco-heading-2 mb-4" style={{fontWeight:"bold"}}>Address</h2>
              <div className="block-23 mb-4">
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <span  style={{ color: "black" }}>
                         
                          {brandName}
                        </span>
                      </li>
                      <li>
                        <span className="text" style={{ color: "black" }}>
                          <IoLocationSharp style={{ marginRight: '8px' }} />
                          {officeInfo.address}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <a className="text-center text-md-left">
                          <IoCallSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.phoneNumber},{officeInfo.phoneNumber1}</span>
                        </a>
                      </li>
                      <li>
                        <a className="text-center text-md-left">
                          <IoMailSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.email}</span>
                        </a>
                      </li>
                      <li>
                        <span className='text'>
                          <IoMapSharp style={{ marginRight: '8px', color: "black" }} />
                          <a href="https://maps.app.goo.gl/CPHkq33Ryk955HJ5A" style={{ color: "black" }}>Google Map</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
            
              <div className="col-md-3 p-4 color-2card ftco-intro container2 " style={{background:"#8c6dfc", borderRadius:"10px"}}>
                <h3 className="mb-4"><FontAwesomeIcon icon={faClock} /> Opening Hours</h3>
                <hr></hr>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Monday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Tuesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Wednesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Thursday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Friday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Saturday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Sunday</span>
                  <span className="time">By Appointment</span>
                </p>
              </div>
            </div>
            
        
      
         <hr style={{color:"black"}}></hr>
          <div className="quick-links">
          <div className='footerq'>
          <p className="pfooter " style={{ color: "black",marginTop:"15px"}}>
            Designed & Developed by
            <a href="https://www.datadna.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#023E8A", marginLeft: '5px' }}>
              DATADNA
            </a>
          </p>
          </div>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/doctors">Doctors</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
        <div className="footer-icons-wrapper">
          <FloatingIcons isFooter={true} />
        </div>
      
    </footer>
  {/* appointment_tab */}
  {/* Modal */}
  {modalVisible && (
    <div className="modal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="modalRequestLabel">Make an Appointment</h5>
        <button type="button" className="close" onClick={() => setModalVisible(false)}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" className="form-control" name="Appointmenttab_name" value={formData.Appointmenttab_name} onChange={handleInputChange} placeholder="Full Name" />
          </div>
          <div className="form-group">
            <input type="email" className="form-control" name="Appointmenttab_email" value={formData.Appointmenttab_email} onChange={handleInputChange} placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="tel" className="form-control" name="Appointmenttab_phone" value={formData.Appointmenttab_phone} onChange={handleInputChange} placeholder="Phone Number" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group foalign">
                <DatePicker
                  selected={formData.Appointmenttab_date}
                  onChange={(date) => setFormData({ ...formData, Appointmenttab_date: date })}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select Date"
                  minDate={new Date()}
                />
                 <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" style={{right:"60px"}}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select className="form-control" name="Appointmenttab_time" value={formData.Appointmenttab_time} onChange={handleTimeChange} placeholder="Select Time">
                  <option value="">Select Time</option>
                  {timeOptions1.map((time, index) => (
                    <option key={index} value={time}>{time}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                      name="Appointmenttab_doctorname" value={formData.Appointmenttab_doctorname} onChange={handleDoctorNameChange} placeholder="Select Doctor"
                      >
                      {doctorOptions.map((doctor, index) => (
                        <option key={index} style={{ color: 'black' }}>{doctor}</option>
                      ))}
                </select>
                </div>
              </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Make an Appointment</button>
          </div>
          {successMessage && <p className="text-success">{successMessage}</p>}
        </form>
      </div>
    </div>
  </div>
</div>

  )}

</>

    );
}
export default Homepage;

